<template>
  <v-app>
    <v-app-bar elevation="4" app dense clipped-left color="primary" dark>
      <div>
        <v-app-bar-nav-icon
          v-on="on"
          v-bind="attrs"
          @click="drawer = !drawer"
        />
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-title> {{ file.name }} </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer clipped app v-model="drawer">
      <div class="mx-4">
        <v-btn
          class="mt-2"
          outlined
          block
          color="primary"
          @click="onBackPressed"
        >
          <v-icon left> mdi-arrow-left </v-icon>
          Volver atrás
        </v-btn>
      </div>

      <div class="mx-4">
        <v-btn
          :disabled="lastWidgetPageEvent.action === 'draw'"
          class="mt-2"
          color="primary"
          block
          @click="createSignature"
        >
          <v-icon left> mdi-shape-square-plus </v-icon>
          Posicionar firma
        </v-btn>
      </div>

      <div class="mx-4">
        <v-btn
          :disabled="lastWidgetPageEvent.action !== 'draw'"
          class="mt-4"
          color="primary"
          block
          @click="summaryDialog = true"
        >
          Finalizar proceso
          <v-icon right> mdi-arrow-right </v-icon>
        </v-btn>
      </div>
    </v-navigation-drawer>

    <v-main class="mt-2">
      <PDFViewer
        @page-focus="updateCurrentPage"
        :file="file"
        @page-count="updatePageCount"
        :currentPage="currentPage"
        :lastWidgetPageEvent="lastWidgetPageEvent"
      >
      </PDFViewer>

      <v-dialog scrollable v-model="summaryDialog" max-width="500">
        <v-form ref="form" class="dialog-custom-form">
          <v-card>
            <v-card-title class="text-h5">
              Detalles del envío
            </v-card-title>

            <v-card-text>
              <v-container class="mb-6">
                <v-select
                  :items="userDevices"
                  item-text="alias"
                  item-value="uid"
                  v-model="userDeviceSelected"
                  label="Seleccionar dispositivo*"
                  :rules="fieldRequerired"
                  class="mt-3"
                  required
                  outlined
                  dense
                ></v-select>

                <v-checkbox
                  v-model="autoOpen"
                  label="Abrir automáticamente en el dispositivo"
                ></v-checkbox>

                <v-checkbox
                  v-model="includeCustomText"
                  label="Incluir texto personalizado"
                ></v-checkbox>
                <span
                  >*Añade el nombre del dispositivo y la hora de firmado</span
                >
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="summaryDialog = false" text color="primary">
                Cancelar
              </v-btn>

              <v-btn
                text
                :disabled="!userDeviceSelected"
                :loading="loading"
                color="primary"
                @click="createDocumentSet"
                >Enviar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-main>
  </v-app>
</template>
<script>
import PDFViewer from "../../../components/pdf/PDFViewer.vue";
import { fabric } from "fabric";
import { InitFabricObjects } from "../../../utils/utils";

export default {
  name: "NewFaceToFaceSignature",

  components: {
    PDFViewer
  },

  props: {
    file: {
      type: File,
      required: true
    },
    docId: {
      type: String,
      required: false
    }
  },

  created() {
    this.userDeviceSelected =
      this.$store.getters["user/userDeviceFav"] &&
      this.$store.getters["user/userDeviceFav"].uid;
  },

  mounted() {
    if (!this.file) {
      this.$router.replace({ name: "FaceToFaceSignatures" });
      this.$toasted.global.toast_error({
        message: "El archivo seleccionado no es válido"
      });
    }
    if (!this.$store.getters["user/countUserDevices"]) {
      this.$router.replace({ name: "FaceToFaceSignatures" });
      this.$toasted.global.toast_error({
        message: "Actualmente no tienes dispositivos asociados"
      });
    }
    if (!this.havePlanWriteMode) {
      window.toasted.clear();
      const toastInfo = this.$toasted.global.toast_info({
        message:
          "No podrá crear un nuevo sobre con su plan actual. Pulse aquí para mejorar plan."
      });
      toastInfo.el.classList.add("cursor-pointer");
      toastInfo.el.onclick = () => {
        this.$store.dispatch("setSeeUpgradePurchase", true);
      };
    }
    InitFabricObjects();
  },

  data: () => ({
    drawer: true,

    userDeviceSelected: undefined,
    autoOpen: false,
    currentPage: 1,
    pageCount: undefined,
    includeCustomText: true,
    fieldRequerired: [v => !!v || "El campo es requerido"],
    lastWidgetPageEvent: {
      page: 1,
      action: "",
      widget: undefined
    },
    summaryDialog: false,

    createDocumentBody: {
      deviceId: "",
      widget: {},
      document: {},
      autoOpen: true
    },
    loading: false
  }),

  methods: {
    onBackPressed() {
      this.$router.back();
    },

    createSignature() {
      if (this.lastWidgetPageEvent.action != "draw") {
        var self = this;

        fabric.Image.fromURL(
          ".../../../../assets/position-signature-image.svg",
          function(positionSignatureImage) {
            fabric.Image.fromURL(".../../../../assets/close.png", function(
              imageClose
            ) {
              var imgClose = imageClose.set({
                originX: "center",
                originY: "center",
                hoverCursor: "pointer",
                id: "delete-btn-" + "position-signature",
                scaleX: 0.2,
                scaleY: 0.2
              });

              var widget = new fabric.Group(
                [positionSignatureImage, imgClose],
                {
                  lockScalingFlip: true,
                  subTargetCheck: true,
                  id: "position-signature"
                }
              );

              self.lastWidgetPageEvent = {
                page: self.currentPage,
                action: "draw",
                widget: widget
              };
              imgClose.on("mousedown", self.onClickDeleteCanvasBtn);
            });
          }
        );
      }
    },

    getWidgetCustomText() {
      if (this.includeCustomText) {
        var userDeviceFound = this.userDevices.find(device => {
          return device && device.uid == this.userDeviceSelected;
        });

        var widgetCustomText = [
          {
            text: "Documento firmado electrónicamente",
            fontSize: 8
          },
          {
            text: "Con dispositivo " + userDeviceFound.alias,
            fontSize: 8
          },
          {
            text: "A las ##TIMESTAMP##",
            fontSize: 8
          }
        ];
        return widgetCustomText;
      } else {
        return [];
      }
    },

    onClickDeleteCanvasBtn(e) {
      console.log(e);
      this.lastWidgetPageEvent = {
        page: this.lastWidgetPageEvent.page,
        action: "delete",
        widget: this.lastWidgetPageEvent.widget
      };
    },

    closeDocument() {
      window.toasted.clear();
      this.$router.back();
    },
    updatePageCount(pageCount) {
      this.pageCount = pageCount;
    },

    updateCurrentPage(pageNumber) {
      this.currentPage = pageNumber;
    },

    async createDocumentSet() {
      this.createDocumentBody.deviceId = this.userDeviceSelected;
      this.createDocumentBody.autoOpen = this.autoOpen;
      let fabricWidget = this.lastWidgetPageEvent.widget;
      let coordY = parseInt(
        fabricWidget.canvas.height -
          fabricWidget.top -
          fabricWidget.height * fabricWidget.scaleY
      );
      let coordX = parseInt(fabricWidget.left);
      if (coordY < 0) {
        coordY = 0;
      }
      if (coordX < 0) {
        coordX = 0;
      }
      const widget = {
        customText: this.getWidgetCustomText(),
        width: parseInt(fabricWidget.width * fabricWidget.scaleX),
        height: parseInt(fabricWidget.height * fabricWidget.scaleY),
        page: this.lastWidgetPageEvent.page,
        x: coordX,
        y: coordY
      };
      var b64PDFContent = await this.getFileB64(this.file);

      var document = {
        id: this.docId || "",
        name: this.file ? this.file.name : "edatalia_document_default.pdf",
        b64PDFContent: b64PDFContent.split(",")[1]
      };
      this.createDocumentBody.widget = widget;
      this.createDocumentBody.document = document;
      this.loading = true;

      this.$store
        .dispatch("createNewSignFromAppItem", this.createDocumentBody)
        .then(response => {
          if (response.status === 200) {
            this.processOkCreateDocumentSet();
          } else {
            this.processErrorCreateDocumentSet();
          }
        })
        .catch(error => {
          this.processErrorCreateDocumentSet();
          console.log(error);
        });
    },

    processOkCreateDocumentSet() {
      this.summaryDialog = false;
      this.loading = false;
      this.$toasted.global.toast_success({
        message:
          "La solicitud: " +
          this.createDocumentBody.document.name +
          ", se ha creado correctamente"
      });
      this.$router.back();
      window.DoSigned();
    },

    processErrorCreateDocumentSet() {
      window.hasErrorSign = true;
      this.summaryDialog = false;
      this.loading = false;
      this.$toasted.global.toast_error({
        message:
          "La solicitud: " +
          this.createDocumentBody.document.name +
          ", ;no se ha podido crear correctamente"
      });
    },

    async getFileB64(file) {
      const toBase64 = file =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      return await toBase64(file);
    }
  },

  computed: {
    userDevices() {
      return this.$store.getters["user/userProfile"].devices;
    },
    havePlanWriteMode() {
      return this.$store.getters.havePlanWriteMode;
    }
  }
};
</script>
